import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Router from 'next/router';
import { i18n, _getNamespaces, useTranslation } from '../i18n';
import Cookies from 'js-cookie';

// helpers
import { getBaseUrl, getScope } from '../scopes';
import { isPathAvailableForOktaGroup } from './AdminLinks';
import { isPathDisabledForUser, unAuthorizedPath } from './Links';
import conditional from 'helpers/conditional/conditional';
import isType1Type2Dashboard from 'helpers/is-type1-type2-dashboard/isType1Type2Dashboard';

// constants
import { BNPP } from 'constants/banks';
import { CLIENT_DEACTIVATED } from 'constants/clientStatuses';
import { UNDER_RESELLER } from 'constants/clientType';
import { TYPE_THREE } from 'constants/memberTypes';
import { DEACTIVATED, INITIATED, INVITED } from 'constants/userStatuses';

const { publicRuntimeConfig } = require('next/config').default();

class AppController {
    constructor(req, res) {
        this.req = req;
        this.res = res;

        const host = req ? req?.headers?.host : window.location.host;
        this.scope = getScope(host);
        this.dashboardLanguage = '';
    }

    /* istanbul ignore next */
    async changeLanguage() {
        if (this.scope === BNPP && this.dashboardLanguage === 'undefined') {
            await i18n.changeLanguage('fr');
        }
    }

    static getTranslation(scope, namespace) {
        let convertedNamespaces;

        if (!Array.isArray(namespace)) {
            convertedNamespaces = _getNamespaces([namespace], scope);
        } else {
            convertedNamespaces = _getNamespaces(namespace, scope);
        }

        return useTranslation(convertedNamespaces);
    }

    getNamespaces(namespaces) {
        return _getNamespaces(namespaces, this.scope);
    }

    // Try to load props from sessionStorage first, if not
    // present then go to the server.
    async getInitialProps() {
        let initialProps = this._fetchFromLocal();
        if (!initialProps) {
            initialProps = await this._fetchFromServer();
        }

        if (unAuthorizedPath(initialProps) || isPathDisabledForUser(initialProps, this.scope)) {
            const error = new Error('Response not found');

            error.code = 'ENOENT';
            error.statusCode = 404;

            throw error;
        }

        return initialProps;
    }

    async _fetchUserAccounts() {
        const userAccounts = [];
        const requestOptions = this.getRequestOptions();

        const constructUrl = (offset = '0') => {
            const params = new URLSearchParams({
                limit: '20',
                offset: offset,
            });
            return params.toString();
        };

        try {
            let response = await axios.get(
                `/api/user/user-accounts?${constructUrl()}`,
                requestOptions,
            );
            const { userAccounts: fetchedAccounts, pageInfo } = response.data;
            userAccounts.push(...fetchedAccounts);

            let { nextOffset: offset, haveMore } = pageInfo;

            while (haveMore) {
                response = await axios.get(
                    `/api/user/user-accounts?${constructUrl(offset)}`,
                    requestOptions,
                );
                const { userAccounts: moreAccounts, pageInfo: morePageInfo } = response.data;
                userAccounts.push(...moreAccounts);
                offset = morePageInfo.nextOffset;
                haveMore = morePageInfo.haveMore;
            }
        } catch (error) {
            console.error('Error fetching user accounts:', error);
        }

        return userAccounts;
    }

    async validateOktaSession() {
        try {
            if (!this.req) await axios.get('/api/admin', this.getRequestOptions());
            let initialProps = {
                groups: [],
            };
            initialProps = await this.getAdminDashboardInitialProps(initialProps);
            return initialProps;
        } catch (e) {
            console.log(e);
            if (e.statusCode === 404) {
                throw e;
            }
            const error = new Error('Unauthorized');

            error.statusCode = 403;
            console.error(error);
            throw error;
        }
    }

    async getAdminDashboardInitialProps(initialProps) {
        let props = {
            ...initialProps,
        };
        let oktaUserGroup = [];
        if (this.req) {
            const token = this.req.headers['x-pomerium-jwt-assertion'];
            const claims =
                process.env.TOKEN_CLUSTER === 'localhost'
                    ? { groups: ['Frontend'] }
                    : jwtDecode(token);
            oktaUserGroup = claims.groups;
        } else {
            const response = await axios.get('/api/admin/okta-user', this.getRequestOptions());
            oktaUserGroup = response.data.groups;
        }

        props = { ...initialProps, groups: oktaUserGroup || [] };
        const url = this.req ? this.req.url : Router.route;
        if (!isPathAvailableForOktaGroup(props.groups, url)) {
            const error = new Error('Response not found');

            error.code = 'ENOENT';
            error.statusCode = 404;

            throw error;
        }
        return props;
    }

    async _fetchFromServer() {
        const props = {};
        try {
            const requestOptions = this.getRequestOptions();

            const [user, member, client] = await Promise.all([
                axios.get('/api/user', requestOptions),
                axios.get('/api/member', requestOptions),
                axios.get('/api/client', requestOptions),
            ]);

            let userAccounts;
            if (publicRuntimeConfig.userAccounts && isType1Type2Dashboard(this.scope)) {
                userAccounts = await this._fetchUserAccounts();
            }

            // APE-2224 | Language preference by user in TPP Dashboard
            const memberId = conditional(
                member?.data?.subTppId,
                member?.data?.subTppId,
                member?.data?.memberId,
            );
            const features = await axios.get(
                `/api/tppIntegration/features/${memberId}`,
                requestOptions,
            );
            const { configurations } = features?.data;

            // language configurations
            const unconfiguredBNPP =
                this.scope === BNPP &&
                (configurations?.dashboardLanguage === 'undefined' || !configurations);
            this.dashboardLanguage = configurations?.dashboardLanguage;
            const selectFrench = configurations?.dashboardLanguage === 'fr' || unconfiguredBNPP;
            const changeLangTo = selectFrench ? 'fr' : 'en';
            if (this.res) {
                this.res.setHeader('Set-Cookie', [`next-i18next=${changeLangTo}`]);
                // set Content-Language
                this.res.setHeader('Content-Language', changeLangTo);
            }
            if (typeof window !== 'undefined') {
                Cookies.set('next-i18next', changeLangTo, { sameSite: 'strict' });
            } else {
                console.error('No response or window object found');
            }

            // to control what under reseller can display
            let displaySettings = {};
            if (client?.data?.clientType === UNDER_RESELLER && member?.data?.memberId) {
                try {
                    const dSRes = await axios.get(
                        `/api/tppIntegration/feature-control/${member.data.memberId}`,
                        requestOptions,
                    );
                    displaySettings = dSRes.data;
                } catch (e) {
                    // DO NOTHING
                }
            }

            Object.assign(props, {
                user: user.data,
                member: member.data,
                userAccounts,
                route: this.req ? this.req.url : Router.route,
                query: this.req ? this.req.query : Router.query,
                host: this.req ? this.req.headers.host : window.location.host,
                client: client.data,
                displaySettings,
                changeLangTo,
            });
            await this._checkUserStatus(props.user);
            await this._checkClientStatus(props.client.clientStatus);
        } catch (e) {
            if (this.req) {
                if (typeof this.req.url === 'string' && this.req.url.startsWith('/swagger')) {
                    return {};
                }
                // swagger page open to non logged in users
                if (this.req?.headers?.cookie?.indexOf('session=') < 0) {
                    this.res.redirect('/signin?errorCode=SESSION_EXPIRED');
                }
                this.res.redirect('/signin?errorCode=LOGIN_SYSTEM_ERROR');
            } else {
                await Router.push('/signin?errorCode=LOGIN_SYSTEM_ERROR');
            }

            console.error(e);

            Object.assign(props, {
                error: true,
            });
        }

        return props;
    }

    getRequestOptions() {
        return AppController._getRequestOptions(this.req, this.scope);
    }

    static _getRequestOptions(req, scope) {
        const requestOptions = {
            baseURL: '',
        };

        if (req) {
            requestOptions.baseURL += getBaseUrl(scope);
            requestOptions['headers'] = {
                cookie: req.headers.cookie || '',
            };
        }

        return requestOptions;
    }

    _fetchFromLocal() {
        if (this.req) {
            return null;
        }

        let user = window.sessionStorage.getItem('user');
        let member = window.sessionStorage.getItem('member');
        let client = window.sessionStorage.getItem('client');
        let userAccounts = window.sessionStorage.getItem('userAccounts');
        let displaySettings = window.sessionStorage.getItem('displaySettings');

        // Check if the key exists
        if (!(user && member && client)) {
            return null;
        }

        user = JSON.parse(user);
        member = JSON.parse(member);
        client = JSON.parse(client);
        userAccounts = JSON.parse(userAccounts);
        displaySettings = JSON.parse(displaySettings);

        // Check if the value stored is valid
        if (!(user && member && client)) {
            return null;
        }

        return {
            user,
            member,
            client,
            userAccounts,
            route: Router.route,
            query: Router.query,
            host: window.location.host,
            displaySettings,
        };
    }

    async _checkUserStatus(user) {
        if (user.status === INITIATED || user.status === INVITED) {
            // If the user doesn't have an initiated account send the user home
            if (this.req) {
                if (!['/', '/signin', '/home'].includes(this.req.url)) {
                    this.res.redirect('/home');
                }
            }
        } else if (user.status === DEACTIVATED) {
            // If the user doesn't have an active account send the login screen
            if (this.req) {
                this.res.redirect('/signin?errorCode=DEACTIVATED');
            } else {
                await Router.push('/signin?errorCode=DEACTIVATED');
            }
        }
    }

    async _checkClientStatus(clientStatus) {
        if (clientStatus === CLIENT_DEACTIVATED) {
            if (this.req) {
                this.res.redirect('/signin?errorCode=DEACTIVATED');
            } else {
                await Router.push('/signin?errorCode=DEACTIVATED');
            }
        }
    }

    static clearStorageSession() {
        window.sessionStorage.removeItem('user');
        window.sessionStorage.removeItem('member');
        window.sessionStorage.removeItem('client');
        window.sessionStorage.removeItem('email');
        window.sessionStorage.removeItem('rememberMe');
    }

    static getAliasFromMember({ member, user }) {
        let aliases = [];

        if (member.memberType === TYPE_THREE) {
            aliases = member.aliases.filter((alias) => alias.type === 'EIDAS');

            // Return non stub alias
            if (aliases.length > 1) {
                return aliases.find((alias) => alias.value !== user.email);
            } else if (aliases.length === 1) {
                return aliases[0];
            }
        }

        aliases = member.aliases.filter((alias) => alias.type === 'DOMAIN');

        if (!aliases?.length) {
            console.error(`No aliases found for member: ${member.memberId}`);
            return {};
        }

        // Return the only alias
        return aliases[0];
    }
}

export default AppController;
