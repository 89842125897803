export default function buildLightstepErrorParams(errorMessage: string) {
    if (typeof errorMessage !== 'string') {
        return {};
    }

    let errorPriority = 'medium';

    if (hasPriorityStatus(lowPriorityErrors, errorMessage)) {
        errorPriority = 'low';
    }

    return {
        errorMessage,
        errorPriority,
    };
}

function hasPriorityStatus(priorityErrors: string[], errorMessage: string) {
    return priorityErrors.reduce((acc: boolean, error: string) => {
        if (!!acc || errorMessage.includes(error)) {
            return true;
        }
        return acc;
    }, false);
}

const lowPriorityErrors = [
    'We are unable to sign you in. If the problem persists',
    'Your email or password is incorrect.',
    'We are unable to reset your password. Please try again later.',
    'Nous ne pouvons pas réinitialiser votre mot de passe.',
    'Error in storing lightstep snapshots "Request failed with status code 403"',
    'IBAN validation failed with status',
] as string[];
