import React from 'react';
import { AppStoreProps, MuiStyles, Translation } from 'types';
import type AuthenticationKeysStore from 'components/authentication-keys/AuthenticationKeysStore';
import type BankStore from 'components/bank-search/bank/BankStore';
import type { AIS, PIS, CBPII } from 'constants/bankConfigEndpoints';
import type BeneficiaryStore from 'components/beneficiary/BeneficiaryStore';
import type BillingCodesStore from 'components/billing-codes/BillingCodesStore';
import type FeatureStore from 'components/configuration/customization/features/FeatureStore';
import type ModalStore from './ModalStore';
import type { AppStore } from 'AppStore';
import type IpAddressModalStore from 'components/common/modals/ip-address/IpAddressModalStore';
import type PaymentInitiationRestrictionsModalStore from 'components/common/modals/payment-initiation-restrictions/PaymentInitiationRestrictionsModalStore';
import type HomeRedirectModalStore from 'components/home/HomeRedirectModalStore';
import type SuperAdminModalStore from 'components/common/modals/super-admin/SuperAdminModalStore';
import type SupportTicketModalStore from './support-ticket/SupportTicketModalStore';
import type TppManagerStore from 'components/tpp-manager/TppManagerStore';
import type UpdatePermissionsModalStore from './update-permissions/UpdatePermissionsModalStore';
import type UserModalStore from './user/UserModalStore';
import type UserActivityStore from 'components/users/activity/UserActivityStore';
import type PaymentHistoryModalStore from './vrp/payment-history/PaymentHistoryModalStore';
import type { i18nMap } from 'constants/errors';
import type BankConfigurationStore from 'components/bank-configuration/BankConfigurationStore';
import type BankRegistrationStore from 'components/bank-registration/BankRegistrationStore';
import type UploadCertificateStore from 'components/certificate-management/upload-certificate/UploadCertificateStore.js';
import type OTPModalStore from './multi-factor-authentication/one-time-password/OTPModalStore';
import type MFACodeModalStore from './multi-factor-authentication/mfa-code/MFACodeModalStore';
import type CreateSubTppModalStore from './create-sub-tpp/CreateSubTppModalStore';
import type {
    IdefaultDetails,
    Imerchant,
    ISubTpp,
} from 'components/admin/members/sub-tpp/details/types';
import SettlementRuleModalStore from './settlement-rule/SettlementRuleModalStore';
import { IDialogWrapperProps } from '../types';

export interface IAuthCodeModal {
    open: boolean;
    isWarning?: boolean;
    onClose?: () => void;
    title?: string;
    subTitle?: string;
    onConfirm: () => void;
    onResend: () => void;
    maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    handleChange: (e: string) => void;
}

export interface IAuthenticationKeysStore {
    AuthenticationKeysStore?: AuthenticationKeysStore;
}

export interface IDeletePublicKeyModal extends AppStoreProps, IAuthenticationKeysStore, MuiStyles {}

export interface IPublicKeyModal extends AppStoreProps, IAuthenticationKeysStore {
    classes?: any;
}

export interface IApiKeyModal extends AppStoreProps, IAuthenticationKeysStore, MuiStyles {}

export interface IConnectivityStatus extends AppStoreProps {
    BankStore?: BankStore;
}

export interface IRootConnectivityStatusStore {
    bankConfigEndpoints: any;
    setBankConfigEndpoints: (...args: any[]) => any;
    RouteChangeStore: any;
    createBankConfigEndpoint: (...args: any[]) => any;
    isEndpointDirty: (...args: any[]) => any;
}

export type FeatureTypes = 'AIS' | 'PIS' | 'CBPII';

export interface IConnectivityStatusRow {
    isDirty: boolean;
    featureType: FeatureTypes;
    endpoint: string;
    status: string;
    tokenSupport: string;
    bankSupport: string;
    bankSpecificationLink: string;
    targetDate: string;
}

export type AISTypeKeys = keyof typeof AIS;
export type PISTypeKeys = keyof typeof PIS;
export type CBPIITypeKeys = keyof typeof CBPII;

export interface IfeatureEndpoints {
    AIS: string[];
    PIS: string[];
    CBPII: string[];
}

export interface IcurrentEndpointByType {
    AIS: string | boolean;
    PIS: string | boolean;
    CBPII: string | boolean;
}

export interface IBeneficiaryStoreProps {
    BeneficiaryStore?: BeneficiaryStore;
}

export interface IBeneficiaryModalProps extends AppStoreProps, IBeneficiaryStoreProps {
    classes?: any;
}

export interface IBicModal extends AppStoreProps, IBeneficiaryStoreProps {}

export interface IBillingCodesStoreProps {
    BillingCodesStore?: BillingCodesStore;
}

export interface IAccountNumberModalProps extends AppStoreProps, IBillingCodesStoreProps {
    classes?: any;
}

export interface IBillingCodesModalProps extends AppStoreProps, IBillingCodesStoreProps {
    classes?: any;
}

export interface IBanksModalProps {
    t: Translation;
    open: boolean;
    onClose: () => void;
    title: string;
    FeatureStore?: FeatureStore;
    BanksModalStore?: FeatureStore;
}

export interface ICountryModalProps {
    Store: ModalStore;
    buttonText?: string;
    onSave: (countries: string[]) => void;
    title?: string;
    countries: string[];
    permittedCountries: string[];
    t: Translation;
}

export interface ISubTppList {
    id: string;
    memberId: string;
    name: string;
    domain: string;
    domicileCountry: string;
    logo: ArrayBuffer | string | null;
    status: string;
    createdAt: number;
    updatedAt: number;
}

export interface ICreateSubTppModalProps extends AppStoreProps {
    open: boolean;
    onClose: () => void;
    CreateSubTppModalStore: CreateSubTppModalStore;
    isEdit?: boolean;
}

export interface IShouldCreateSubTppUserProps extends AppStoreProps {
    open: boolean;
    closeModal: () => void;
    handleCreateSubTppUser: () => void;
}

export interface IcreateSubTppModalRootStore {
    subTppList?: ISubTppList[];
    openSuccessModal?: () => void;
    subTpp?: IdefaultDetails;
    memberId?: string;
    apiData?: ISubTpp | null;
    addUser?: () => void;
    setClientId?: (clientId: string) => void;
    handleFetch?: (AppStore: AppStore) => void;
    permissions?: string[];
    toggleShouldCreateSubTppUserModal?: () => void;
}

export interface ICountry {
    code: string;
    name: string;
}

export interface IParentSubTppId {
    name: string;
    id: string;
}

export interface ICreateSubTppModalForm
    extends Omit<
        ISubTpp & Imerchant,
        // keys not required for form input
        'merchant' | 'id' | 'status' | 'memberId' | 'createdAt' | 'updatedAt' | 'mccCode'
    > {
    parentSubTpp?: IParentSubTppId;
    mccCode: IMccCode;
}

export interface IMccCode {
    mcc: '';
    edited_description: '';
}

export interface IKeyValues {
    key: string;
    value: any;
}

export interface IDisplayModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    onOkay: () => Promise<void> | void;
    keyValues?: IKeyValues[];
    maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    subTitle?: string;
    loading?: boolean;
    isWarning?: boolean;
    customContent?: JSX.Element;
    customFooter?: JSX.Element;
    disableOkayBtn?: boolean;
    disableCloseOnOkay?: boolean;
}

export interface IDisplayModalEditableField {
    key: string;
    defaultValue: any;
    required: boolean;
}

export interface IGenericErrorModalProps extends AppStoreProps {
    Store: ModalStore;
    onClick?: () => Promise<void>;
}

export interface IInitiateRefundAuthFlowModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    subTitle?: string;
    onConfirm: () => Promise<void>;
    onResend: () => Promise<void>;
    maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    callback: (arg0: string) => void;
}

export interface IIpAddressModalProps extends AppStoreProps {
    IpAddressModalStore: IpAddressModalStore;
}

export interface IRestrictionRowChild {
    id: string;
    name: string;
    text: string;
    enabled?: boolean;
    createdAt?: Date | string;
}

export interface IRestrictionRow {
    id: string;
    targetMemberId: string;
    ownerMemberId: string;
    productType: string;
    enabled: boolean;
    childrenList: IRestrictionRowChild[];
    createdAt?: Date | string;
}

export interface IRestrictionRowIp {
    id: string;
    targetMemberId: string;
    ownerMemberId: string;
    productType: string;
    enabled: boolean;
    rules: IRestrictionRowChild[];
    createdAt?: Date | string;
}

export interface IRestrictionRule extends IRestrictionRowChild {}

export interface IPaymentInitiationRestrictionsModalProps extends AppStoreProps {
    PaymentInitiationRestrictionsModalStore: PaymentInitiationRestrictionsModalStore;
}

export interface IFieldValuesPaymentInitRest {
    payerCountry: string[];
    paymentCurrency: string[];
    [k: string]: string[];
}

export interface IReasonModalProps {
    open: boolean;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    buttonText?: string;
    onConfirm: (val: string) => Promise<void>;
    onClose: (e: React.MouseEvent) => void;
    title?: string;
    subtitle?: string;
}

export interface IRedirectModalStoreProp {
    RedirectModalStore: HomeRedirectModalStore;
}

export interface IRedirectModalProps extends AppStoreProps, IRedirectModalStoreProp {}

export type IgetCopy = (arg0: {
    scope: string;
    isUnderReseller: boolean;
    isTypeOne: boolean;
    isProd: boolean;
}) => { title: string; description: string };

export interface IInitiateRefundModalProps {
    t: Translation;
    open: boolean;
    onClose: () => void;
    title?: string;
    buttonText?: string;
    onConfirm: (form: any) => Promise<void> | void;
    row: any;
    loading: boolean;
    isDisabled: boolean;
}

export interface IKeysInitRefund {
    remainingAmount: {
        text?: string;
        icon: JSX.Element;
    };
}

export interface IRichEditorModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    plainText?: string;
    disabled?: string[];
    placeholder?: string;
    buttonText?: string;
    onConfirm: (val: DeserializeSlateReturnType) => void;
    displayKey?: string;
    tags?: string[];
    tagTitle?: string;
    formatHtml?: (t: string) => string | Node;
    hidePreview?: Boolean;
    maxWidth?: IDialogWrapperProps['maxWidth'];
}

export interface IMFAModalProps extends AppStoreProps {
    MFACodeModalStore: MFACodeModalStore;
}

export interface IOTPModalProps extends AppStoreProps {
    OTPModalStore: OTPModalStore;
}

export type DeserializeSlateReturnType =
    | {
          type: string;
          children: {
              text: string;
          }[];
      }[]
    | {
          type: string;
          align: any;
          children: {
              type: string;
              children: {
                  text: string;
              }[];
          }[];
      }[];

export interface IGenericSuccessModalProps {
    className?: string;
    Store: ModalStore;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    icon: string;
    buttonText?: string;
    onClick?: () => Promise<void> | void;
    title?: string;
    subtitle?: string;
    link?: string;
}

export interface ISuperAdminModalStoreProp {
    SuperAdminModalStore: SuperAdminModalStore;
}

export interface ISuperAdminModalProps extends AppStoreProps, ISuperAdminModalStoreProp {}

export interface IFormSuperAdminModalStore {
    clientId: string;
    firstName: string;
    lastName: string;
    email: string;
    products: string[];
    aliasValue: string;
    memberType: string;
    selectAll: {
        products: boolean;
    };
    scope: string;
    resellerName: string;
    [k: string]: any;
}

export interface ISupportTicket {
    requestFieldValues?: {
        fieldId: string;
        value: string;
        label?: string;
        renderedValue?: {
            html: string;
        };
    }[];
    issueKey: string;
    _links?: {
        web: string;
    };
    refId?: string;
    error: string;
    webLink?: string;
}

export interface ISupportTicketProp {
    SupportTicketModalStore: SupportTicketModalStore;
}

export interface ISupportTicketModalProps extends AppStoreProps, ISupportTicketProp, MuiStyles {}

export interface ITermsAndConditionsModalProps extends AppStoreProps {
    TermsAndConditionsStore: ModalStore;
}

export interface IRegenerateBillingFileModalProps extends AppStoreProps {
    TppManagerStore?: TppManagerStore;
}

export interface IUpdatePermissionsModalProps extends AppStoreProps {
    UpdatePermissionsModalStore: UpdatePermissionsModalStore;
}

export interface IUserModalProps extends AppStoreProps {
    UserModalStore: UserModalStore;
    classes?: any;
    hideRole?: boolean;
}

export interface ISettlementRuleModalProps extends AppStoreProps {
    SettlementRuleModalStore: SettlementRuleModalStore;
    classes?: any;
}

export interface IViewDetailsModalProps extends AppStoreProps {
    UserActivityStore?: UserActivityStore;
    classes?: any;
}

export interface IFormattedVrp {
    id: string;
    memberId: string;
    consentId: string;
    refId: string;
    remittanceInformationPrimary: string;
    currency: string;
    value: string;
    createdDateTime: string;
    updatedDateTime: string;
    status: string;
}

export interface IVrp {
    id: string;
    memberId: string;
    initiation: {
        consentId: string;
        refId: string;
        remittanceInformationPrimary: string;
        amount: {
            currency: string;
            value: string;
        };
    };
    createdDateTime: string;
    updatedDateTime: string;
    status: string;
}

export interface IPaymentHistoryModalProps extends AppStoreProps {
    PaymentHistoryModalStore: PaymentHistoryModalStore;
}

export interface IGenericWarningModalProps extends AppStoreProps {
    Store: any;
    title: string;
    subtitle?: string;
    buttonText: string;
    onClick: (AppStore: AppStore) => void;
    loading?: boolean;
    classes?: any;
}

export type I18nMapKeys = keyof typeof i18nMap;

export type DefaultSettingsModalStoreRoot =
    | BankConfigurationStore
    | BankRegistrationStore
    | UploadCertificateStore
    | any;

type BankRegistrationStoreProp = {
    BankRegistrationStore?: BankRegistrationStore;
};

export interface IRegistrationDetailsModalProps extends AppStoreProps, BankRegistrationStoreProp {}

export interface IbankConfigs {
    bankName: string;
    type: string;
    instructions?: string;
    devPortal?: string[];
    supportEmail?: string;
}

type BankConfigStoreProp = {
    BankConfigurationStore?: BankConfigurationStore;
};

export interface IBankStandardsProps extends AppStoreProps, BankConfigStoreProp {}

export interface IDefaultSettingsModalProps extends IBankStandardsProps {
    next?: () => void;
}

export interface IImportConfigProps extends IBankStandardsProps {
    classes?: any;
}

export interface IOperationMenuProps {
    className?: string;
    t: Translation;
    bankEnabled?: boolean;
    onAdd?: () => void;
    onDelete?: () => void;
    onEdit?: () => void;
}

export enum Operation {
    ADD = 'add',
    DELETE = 'delete',
    EDIT = 'edit',
    NOOP = '',
    ADD_IP_RESTRICTION = 'addIpRestriction',
    MODIFY_IP_RESTRICTION = 'modifyIpRestriction',
    MODIFY_IP_RULE = 'modifyIpRule',
    DELETE_IP_RULE = 'deleteIpRule',
    MODIFY_RESTRICTION = 'modifyRestriction',
    MODIFY_RULE = 'modifyRule',
    ADD_RESTRICTION = 'addRestriction',
    ADD_RULE = 'addRule',
}

export enum BANKING_STANDARDS {
    UK_OPENBANKING = 'ukOpenBankingStandard',
    POLISH_API = 'polishApiStandard',
    NEXTGEN_PSD2 = 'nextGenPsd2Standard',
    STET_PSD2 = 'stetPsd2Standard',
}

export type Money = {
    currency: string;
    value: string;
};

export enum LocalInstrument {
    INVALID_LOCAL_INSTRUMENT = 0,
    SEPA = 1,
    SEPA_INSTANT = 2,
    SWIFT = 3,
    FASTER_PAYMENTS = 4,
    CHAPS = 5,
    BACS = 6,
    ELIXIR = 7,
    EXPRESS_ELIXIR = 8,
    BLUE_CASH = 9,
    SORBNET = 10,
    BANKGIRO = 11,
    PLUSGIRO = 12,
    AFR = 13,
}

export type DebtorInformation = {
    name: string;
    ultimateDebtorName: string;
    address: string;
    bic: string;
    sortCode: string;
    iban: string;
    accountNumber: string;
    bankgiroNumber: string;
    plusgiroNumber: string;
};

export type Address = {
    addressLine: string[];
    streetName: string;
    buildingNumber: string;
    postCode: string;
    townName: string;
    countrySubDivision: string[];
    country: string;
};

export type CreditorInformation = {
    name: string;
    ultimateCreditorName: string;
    bankName: string;
    address: Address;
    bic: string;
    sortCode: string;
    iban: string;
    accountNumber: string;
    bankgiroNumber: string;
    plusgiroNumber: string;
};

export type Authentication = {
    redirectUrl: string;
    description: string;
    image: string;
    flickerCode: string;
    isRetry: boolean;
    fields: EmbeddedAuthField[];
};

export enum CredentialType {
    INVALID_TYPE = 0,
    FIELD = 1,
    PASSWORD = 2,
    CHOICE_FIELD = 3,
    CONFIRMATION = 4,
}

export type EmbeddedAuthField = {
    type: CredentialType;
    id: string;
    displayName: string;
    options: string[];
};

export enum PaymentStatus {
    INVALID_STATUS = 'INVALID_STATUS',
    INITIATION_PENDING = 'INITIATION_PENDING',
    INITIATION_PENDING_MORE_INFO = 'INITIATION_PENDING_MORE_INFO',
    INITIATION_PENDING_REDIRECT_AUTH = 'INITIATION_PENDING_REDIRECT_AUTH',
    INITIATION_PENDING_REDIRECT_AUTH_VERIFICATION = 'INITIATION_PENDING_REDIRECT_AUTH_VERIFICATION',
    INITIATION_PENDING_EMBEDDED_AUTH = 'INITIATION_PENDING_EMBEDDED_AUTH',
    INITIATION_PENDING_EMBEDDED_AUTH_VERIFICATION = 'INITIATION_PENDING_EMBEDDED_AUTH_VERIFICATION',
    INITIATION_PENDING_REDEMPTION = 'INITIATION_PENDING_REDEMPTION',
    INITIATION_PENDING_REDEMPTION_VERIFICATION = 'INITIATION_PENDING_REDEMPTION_VERIFICATION',
    INITIATION_PROCESSING = 'INITIATION_PROCESSING',
    INITIATION_COMPLETED = 'INITIATION_COMPLETED',
    INITIATION_REJECTED = 'INITIATION_REJECTED',
    INITIATION_FAILED = 'INITIATION_FAILED',
    CANCELED = 'CANCELED',
    INITIATION_NO_FINAL_STATUS_AVAILABLE = 'INITIATION_NO_FINAL_STATUS_AVAILABLE',
    INITIATION_PENDING_DECOUPLED_AUTH = 'INITIATION_PENDING_DECOUPLED_AUTH',
    INITIATION_REJECTED_INSUFFICIENT_FUNDS = 'INITIATION_REJECTED_INSUFFICIENT_FUNDS',
    INITIATION_DECLINED = 'INITIATION_DECLINED',
    SETTLEMENT_IN_PROGRESS = 'SETTLEMENT_IN_PROGRESS',
    SETTLEMENT_COMPLETED = 'SETTLEMENT_COMPLETED',
    SETTLEMENT_INCOMPLETE = 'SETTLEMENT_INCOMPLETE',
    INITIATION_EXPIRED = 'INITIATION_EXPIRED',
    INITIATION_PENDING_REDIRECT_HP = 'INITIATION_PENDING_REDIRECT_HP',
}

export type Payment = {
    id: string;
    memberId: string;
    initiation: {
        bankId: string;
        refId: string;
        remittanceInformationPrimary: string;
        remittanceInformationSecondary: string;
        amount: Money;
        localInstrument: LocalInstrument;
        debtor: DebtorInformation;
        creditor: CreditorInformation;
        chargeBearer: string;
        callbackUrl: string;
        callbackState: string;
        returnRefundAccount: boolean;
        disableFutureDatedPaymentConversion: boolean;
        risk: any;
    };
    createdDateTime: string;
    updatedDateTime: string;
    status: PaymentStatus;
    authentication: Authentication;
    refundDetails: any;
    convertedToFutureDatedPayment: boolean;
    bankPaymentStatus: string;
    statusReasonInformation: string;
    bankPaymentId: string;
    bankTransactionId: string;
};

export type Payments = {
    payment: Payment;
};

export interface IKeyCert {
    type: string;
    name: string;
    id?: string;
}

export interface IRegistrationDetailsRow {
    id: string;
    name: string;
    registrationType: string;
    devPortalLinks?: string[];
    supportEmail?: string;
    instructions?: string;
    country: string;
    standard: string;
}
