const BANKS = require('./constants/banks');

const { publicRuntimeConfig } = require('next/config').default();
const { TOKEN, ADMIN, PNB } = BANKS;

const isLocal = publicRuntimeConfig.baseUrl === 'http://localhost:5000';

const requestUrl = isLocal
    ? 'http://<scope>.localhost:5000'
    : publicRuntimeConfig.baseUrl.replace(
        /^https?:\/\/(\S+\.)?(dashboard\.(\S+\.)?token\.io)$/,
        'https://<scope>.$2',
    );

const scopeUrl = requestUrl.replace(/(^\w+:|^)\/\//, '');

const URL = {
    [TOKEN]: publicRuntimeConfig.baseUrl,
};

const SCOPE = {};

for (const bank in BANKS) {
    if (bank === TOKEN) {
        continue;
    }
    const key = BANKS[bank];
    const value = bank;
    URL[value] = requestUrl.replace(/<scope>/g, key.toLowerCase());
    SCOPE[scopeUrl.replace(/<scope>/g, key.toLowerCase())] = value;
}

module.exports = {
    getScope: (host) => {
        if (host && (host === publicRuntimeConfig.adminUrl || host.startsWith('admin'))) {
            return ADMIN;
        }

        if (host) {
            if (host.startsWith('pnb-per')) {
                return PNB;
            }
        }

        return SCOPE[host] || TOKEN;
    },
    getBaseUrl: (scope) => {
        return URL[scope] || URL.TOKEN;
    },
    _URL: URL,
    _SCOPE: SCOPE,
};
